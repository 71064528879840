.App {
  text-align: center;
}

#root {
  height: 100%;
  min-height: 100vh;
}

.step-complete {
  background-color: var(--key-bg) !important;
  color: var(--key-text-color) !important;
  border: 1px solid var(--key-border) !important;
}

.step-total {
  color: var(--key-text-color) !important;
  border: 2px solid var(--key-text-color) !important;
}

.main-container {
  height: 100%;
  min-height: 100vh;
  background-color: var(--color-background) !important;
  color: var(--color-text) !important;
}

.ms-Spinner-label {
  font-size: 1.5rem !important;
}

.main-content {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  background-color: var(--color-background) !important;
  padding-bottom: 10px;
}

.grid-cell {
  height:11vw;
  width:11vw;
  max-width: 54px;
  max-height: 54px;
  padding: 0;
  flex: none;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.grid-cell-small {
  height:9vw;
  width:9vw;
  max-width: 46px;
  max-height: 46px;
  padding:0;
  flex: none;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}


.keyboard-row {
  margin-bottom: 4px !important;
}

.key-standard {
  width: 9vw !important;
  max-width: 43px !important;
  margin-right: 4px !important;
  font-size: .8em !important;
  font-weight: 600 !important;
}

.key-wide {
  width: 13vw !important;
  max-width: 66px !important;
  margin-right: 4px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  font-size: .8em !important;
  font-weight: 600 !important;
}

.navbar-bg {
  background-color: var(--color-background);
  border-bottom: 2px solid var(--color-tone-5);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
