body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Styles & Colors */
:root {
  --green: #6aaa64;
  --darkendGreen: #538d4e;
  --yellow: #c9b458;
  --darkendYellow: #b59f3b;
  --lightGray: #d8d8d8;
  --gray: #86888a;
  --darkGray: #939598;
  --white: #fff;
  --black: #212121;
  /* Colorblind colors */
  --orange: #f5793a;
  --blue: #85c0f9;
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  --header-height: 50px;
  --keyboard-height: 200px;
  --game-max-width: 500px;
  --animation-time: 250ms;
}

@media screen and (max-width: 400px) {
  :root {
    font-size:11px;
  }
}
/* Light theme colors */
:root {
  --color-tone-1: #1a1a1b;
  --color-tone-2: #787c7e;
  --color-tone-3: #878a8c;
  --color-tone-4: #d3d6da;
  --color-tone-5: #edeff1;
  --color-tone-6: #f6f7f8;
  --color-tone-7: #ffffff;
  --opacity-50: rgba(255, 255, 255, 0.5);
}
/* Dark Theme Colors */
.nightmode {
  --color-tone-1: #d7dadc;
  --color-tone-2: #818384;
  --color-tone-3: #565758;
  --color-tone-4: #3a3a3c;
  --color-tone-5: #272729;
  --color-tone-6: #1a1a1b;
  --color-tone-7: #121213;
  --opacity-50: rgba(0, 0, 0, 0.5);
}
/* Constant colors and colors derived from theme */
:root,
.nightmode {
  --color-background: var(--color-tone-7);
  --color-header: var(--color-tone-7);
  --color-text: var(--color-tone-1);
}
:root {
  --color-present: var(--yellow);
  --color-correct: var(--green);
  --color-absent: var(--color-tone-2);
  --tile-text-color: var(--color-tone-7);
  --key-text-color: var(--color-tone-1);
  --key-evaluated-text-color: var(--color-tone-7);
  --key-border: var(--color-tone-2);
  --key-bg: var(--color-tone-4);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
  --modal-content-bg: var(--color-tone-7);
}
.nightmode {
  --color-present: var(--darkendYellow);
  --color-correct: var(--darkendGreen);
  --color-absent: var(--color-tone-4);
  --tile-text-color: var(--color-tone-1);
  --key-text-color: var(--color-tone-1);
  --key-evaluated-text-color: var(--color-tone-1);
  --key-border: var(--color-tone-2);
  --key-bg: var(--color-tone-2);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
  --modal-content-bg: var(--color-tone-7);
}
.colorblind {
  --color-correct: var(--orange);
  --color-present: var(--blue);
  --tile-text-color: var(--white);
  --key-bg-present: var(--color-present);
  --key-bg-correct: var(--color-correct);
  --key-bg-absent: var(--color-absent);
}

.row-completed {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.row-empty {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.row-current {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3px;
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.delay0 {
  animation-delay: calc(var(--animation-time) * 0) !important;
}

.delay1 {
  animation-delay: calc(var(--animation-time) * 1) !important;
}

.delay2 {
  animation-delay: calc(var(--animation-time) * 2) !important;
}

.delay3 {
  animation-delay: calc(var(--animation-time) * 3) !important;
}

.delay4 {
  animation-delay: calc(var(--animation-time) * 4) !important;
}

.delay5 {
  animation-delay: calc(var(--animation-time) * 5) !important;
}

.delay6 {
  animation-delay: calc(var(--animation-time) * 6) !important;
}

.delay7 {
  animation-delay: calc(var(--animation-time) * 7) !important;
}

.delay8 {
  animation-delay: calc(var(--animation-time) * 8) !important;
}

.delay0-3 {
  animation-delay: calc(var(--animation-time) * 0), calc(var(--animation-time) * 3) !important;
}

.delay1-3 {
  animation-delay: calc(var(--animation-time) * 1), calc(var(--animation-time) * 3) !important;
}

.delay2-3 {
  animation-delay: calc(var(--animation-time) * 2), calc(var(--animation-time) * 3) !important;
}

.delay3-3 {
  animation-delay: calc(var(--animation-time) * 3), calc(var(--animation-time) * 3) !important;
}

.delay0-4 {
  animation-delay: calc(var(--animation-time) * 0), calc(var(--animation-time) * 4) !important;
}

.delay1-4 {
  animation-delay: calc(var(--animation-time) * 1), calc(var(--animation-time) * 4) !important;
}

.delay2-4 {
  animation-delay: calc(var(--animation-time) * 2), calc(var(--animation-time) * 4) !important;
}

.delay3-4 {
  animation-delay: calc(var(--animation-time) * 3), calc(var(--animation-time) * 4) !important;
}

.delay4-4 {
  animation-delay: calc(var(--animation-time) * 4), calc(var(--animation-time) * 4) !important;
}

.delay0-5 {
  animation-delay: calc(var(--animation-time) * 0), calc(var(--animation-time) * 5) !important;
}

.delay1-5 {
  animation-delay: calc(var(--animation-time) * 1), calc(var(--animation-time) * 5) !important;
}

.delay2-5 {
  animation-delay: calc(var(--animation-time) * 2), calc(var(--animation-time) * 5) !important;
}

.delay3-5 {
  animation-delay: calc(var(--animation-time) * 3), calc(var(--animation-time) * 5) !important;
}

.delay4-5 {
  animation-delay: calc(var(--animation-time) * 4), calc(var(--animation-time) * 5) !important;
}

.delay5-5 {
  animation-delay: calc(var(--animation-time) * 5), calc(var(--animation-time) * 5) !important;
}

.delay0-6 {
  animation-delay: calc(var(--animation-time) * 0), calc(var(--animation-time) * 6) !important;
}

.delay1-6 {
  animation-delay: calc(var(--animation-time) * 1), calc(var(--animation-time) * 6) !important;
}

.delay2-6 {
  animation-delay: calc(var(--animation-time) * 2), calc(var(--animation-time) * 6) !important;
}

.delay3-6 {
  animation-delay: calc(var(--animation-time) * 3), calc(var(--animation-time) * 6) !important;
}

.delay4-6 {
  animation-delay: calc(var(--animation-time) * 4), calc(var(--animation-time) * 6) !important;
}

.delay5-6 {
  animation-delay: calc(var(--animation-time) * 5), calc(var(--animation-time) * 6) !important;
}

.delay6-6 {
  animation-delay: calc(var(--animation-time) * 6), calc(var(--animation-time) * 6) !important;
}

.delay0-7 {
  animation-delay: calc(var(--animation-time) * 0), calc(var(--animation-time) * 7) !important;
}

.delay1-7 {
  animation-delay: calc(var(--animation-time) * 1), calc(var(--animation-time) * 7) !important;
}

.delay2-7 {
  animation-delay: calc(var(--animation-time) * 2), calc(var(--animation-time) * 7) !important;
}

.delay3-7 {
  animation-delay: calc(var(--animation-time) * 3), calc(var(--animation-time) * 7) !important;
}

.delay4-7 {
  animation-delay: calc(var(--animation-time) * 4), calc(var(--animation-time) * 7) !important;
}

.delay5-7 {
  animation-delay: calc(var(--animation-time) * 5), calc(var(--animation-time) * 7) !important;
}

.delay6-7 {
  animation-delay: calc(var(--animation-time) * 6), calc(var(--animation-time) * 7) !important;
}

.delay7-7 {
  animation-delay: calc(var(--animation-time) * 7), calc(var(--animation-time) * 7) !important;
}


/* .row-reveal > * {
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-name: shrink;
} */

.cell-reveal {
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.cell-reveal.current {
  animation-name: revealCurrentRow;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell, shrinkCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell, shrinkCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell, shrinkCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.25s linear;
  animation-fill-mode: forwards;
}

svg:hover {
  animation: float infinite;
  animation-duration: var(--animation-speed);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.reveal-border {
  border: 2px solid var(--color-absent);
}

.points-cell-small {
  height:9vw !important;
  width:8vw;
  max-width: 38px !important;
  max-height: 46px !important;
  font-size: .9em !important;
  padding:0 !important;
  padding-left: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reveal-points {
  width: 0vw;
  opacity: 0;
  overflow: hidden;
  animation: .5s revealLTR forwards ease-in;
}

@keyframes revealLTR {
  0% {
    width: 0vw;
    opacity: 0;
  }
  50% {
    width: 8vw;
    opacity: 1;
    transform: scale(1);
  }
  75% {
    width: 8vw;
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    width: 8vw;
    opacity: 1;
  }
}

@keyframes revealCurrentRow {
  0% {
    height:9vw;
    width:9vw;
    max-width: 46px;
    max-height: 46px;
    font-size: 1em;
  }
  100% {
    height:11vw;
    width:11vw;
    max-width: 54px;
    max-height: 54px;
    font-size: 1.5em;
  }
}

@keyframes shrinkCell {
  0% {
    height:11vw;
    width:11vw;
    max-width: 54px;
    max-height: 54px;
    font-size: 1.5em;
  }
  100% {
    height:9vw;
    width:9vw;
    max-width: 46px;
    max-height: 46px;
    font-size: 1em;
  }
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--color-background);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  50% {
    background-color: var(--color-background);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  50.1% {
    background-color: var(--color-absent);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  100% {
    transform: rotateX(180deg);
    background-color: var(--color-absent);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--color-background);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  50% {
    background-color: var(--color-background);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  50.1% {
    background-color: var(--color-correct);
    border-color: var(--color-correct);
    color: var(--tile-text-color);
  }
  100% {
    transform: rotateX(180deg);
    background-color: var(--color-correct);
    border-color: var(--color-correct);
    color: var(--tile-text-color);
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--color-background);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  50% {
    background-color: var(--color-background);
    border-color: var(--color-absent);
    color: var(--tile-text-color);
  }
  50.1% {
    background-color: var(--color-present);
    border-color: var(--color-present);
    color: var(--tile-text-color);
  }
  100% {
    transform: rotateX(180deg);
    background-color: var(--color-present);
    border-color: var(--color-present);
    color: var(--tile-text-color);
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -0.5rem);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}